<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
          <div>
            <h2 class="font-weight-bolder m-0" >
              <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Partes de trabajo de obras
            </h2>
            <h4 class="pl-3 pt-1"> {{task.name}} </h4>
          </div>
        </div>
        <b-col cols="12" v-if="task.journeyTask" >
          <b-card no-body >
            <div class="d-flex align-items-center justify-content-between p-2" >
              <h5 class="m-0" >JORNADAS DE ESTA TAREA</h5>
            </div>
            <b-table
                v-if="task.journeyTask.data.length"
                hover
                responsive
                :items="task.journeyTask.data"
                :fields="columnsJourneyTask"
            >
              <template #cell(day)="data">
                {{ TimestampToFlatPickr(data.item.day) }}
              </template>
              <template #cell(user)="data">
                <span v-if="data.item.user" >{{ data.item.user.name }}</span>
              </template>
              <template #cell(total_hours)="data">
                <span v-if="data.item.total_hours != null" >{{ (data.item.total_hours/60).toFixed(2) }}h</span>
              </template>
              <template #cell(status)="data">
                <b-badge
                    :variant="statusTasksVariant[data.item.status]"
                >
                  {{ statusTasksText[data.item.status] }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
              <span>
                  <a
                      v-if="(data.item.url_pdf)"
                      :href="data.item.url_pdf"
                      target="_blank"
                      download
                      class="d-inline-block px-50 text-dark"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </a>
              </span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow, BLink, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import {config} from "@/shared/app.config";

export default {
  components: {
    BBadge,
    BAvatar,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      statusTasksVariant: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columnsJourneyTask: [
        {
          label: 'Usuario',
          key: 'user',
        },
        {
          label: 'Día',
          key: 'day',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Total Horas',
          key: 'total_hours',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      task: 'tasks/getTask',
      journey: 'journey/getJourney',
    }),
  },
  async created() {
    await this.getTask(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getTask: 'tasks/getTask',
      getJourney: 'journey/getJourney',
    }),
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
  },
}
</script>
